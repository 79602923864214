import React from "react";

const Box: React.FC<{}> = props => {
  return (
    <div className="bg-blue mx-4 flex flex-col items-center justify-center p-6 absolute bottom-0 opacity-80 w-[calc(100%_-_2rem)] md:right-20 md:bottom-20 md:w-auto z-20">
      {props.children}
    </div>
  );
};

export default Box;
