import { Link, PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import MobileNavBar, { DesktopNavBar } from "./MobileNavBar";

const Header = (props: { setHovered: (index: number) => void; effect?: boolean } & Omit<PageProps, "children">) => {
  return (
    <div className="absolute z-10 w-full">
      <div className="bg-grey h-20 flex justify-end items-center p-4 ">
        <div className="absolute h-20 w-full flex justify-center left-0 right-0 items-center ">
          <Link to="/">
            <StaticImage src="../images/logo.png" alt="Logo" className="h-10 w-24" objectFit="scale-down" />
          </Link>
        </div>
        <MobileNavBar />
      </div>
      <div className="bg-blue h-2" />
      <DesktopNavBar {...props} />
    </div>
  );
};

export default Header;
