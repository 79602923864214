import { PageProps } from "gatsby";
import * as React from "react";
import BasicScreen from "../components/BasicScreen";
import Box from "../components/Box";

// markup
const NotFoundPage = (props: PageProps) => {
  return (
    <BasicScreen {...props} effect>
      <Box>
        <span className="mt-6 text-center text-white">404</span>
        <span className="mt-6 text-center text-white">Stranica koju tražite ne postoji!</span>
      </Box>
    </BasicScreen>
  );
};

export default NotFoundPage;
