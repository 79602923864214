import { Link, PageProps } from "gatsby";
import React, { useState } from "react";

const links = [
  { to: "/", title: "POČETNA" },
  { to: "/about/", title: "O NAMA" },
  { to: "/projects/", title: "PROJEKTI" },
  { to: "/contact/", title: "KONTAKT" },
];

export const DesktopNavBar = (
  props: { setHovered: (index: number) => void; effect?: boolean } & Omit<PageProps, "children">,
) => {
  console.log(props?.path, " props?.path");
  return (
    <div
      className={`hidden md:flex  w-full flex-row justify-around ${
        props?.effect ? "absolute h-screen top-0 bottom-0 left-0 right-0 pt-[5.5rem]" : ""
      }`}
    >
      {links?.map((link, index) => (
        <Link
          key={`DESKTOP_MENU_${index}`}
          to={link.to}
          className={`text-2xl text-blue p-2 pt-0 ${
            props?.effect ? "effectLink " : "hover:bg-blue hover:text-white"
          }  w-full text-center  ${props?.path ===link.to && link.to !== "/" ? "text-white" : ""} h-full`}
          onMouseEnter={() => props.setHovered(index)}
          onMouseLeave={() => props?.setHovered(null as any)}
        >
          <div className="effectSpan pt-2">{link.title}</div>
        </Link>
      ))}
    </div>
  );
};
const MobileNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const genericHamburgerLine = `h-1 w-10 my-1 bg-white transition ease transform duration-300`;

  return (
    <>
      <button
        className="md:hidden flex flex-col h-12 w-12  justify-center items-center group"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="absolute h-12 w-12" />
        <div
          className={`${genericHamburgerLine} ${
            isOpen ? "rotate-45 translate-y-3 opacity-50 group-hover:opacity-100" : "opacity-50 group-hover:opacity-100"
          }`}
        />
        <div className={`${genericHamburgerLine} ${isOpen ? "opacity-0" : "opacity-50 group-hover:opacity-100"}`} />
        <div
          className={`${genericHamburgerLine} ${
            isOpen
              ? "-rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100"
              : "opacity-50 group-hover:opacity-100"
          }`}
        />
      </button>

      <div
        className={`md:hidden transition ease delay-150 absolute top-22  min-h-[calc(100vh_-_5.5rem)] w-full left-0 ${
          isOpen ? "opacity-80 bg-black translate-x-0" : "opacity-0 translate-x-full"
        } `}
      />

      <nav
        className={`md:hidden transition transform ease duration-300 bg-blue  absolute top-20  min-h-[calc(100vh_-_5.5rem)] min-w-half  right-0 ${
          isOpen ? " translate-x-0 " : "translate-x-full "
        }`}
      >
        <div className="flex flex-col p-4 items-center">
          {links?.map((link, index) => (
            <Link key={`MOBILE_MENU_${index}`} to={link.to} className="mb-6">
              {link.title}
            </Link>
          ))}
        </div>
      </nav>
    </>
  );
};

export default MobileNavBar;
