import { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Header from "./Header";

const BasicScreen: React.FC<{ effect?: boolean } & Omit<PageProps, "children">> = props => {
  const [hovered, setHovered] = useState<number | null>(null);

  return (
    <div className="basic-screen">
      <Header {...props} setHovered={v => props?.effect && setHovered(v)} />
      <div className="h-screen w-full -z-50 absolute ">
        <StaticImage
          src="../images/background-greyscale.jpg"
          alt="Logo"
          className="h-full w-full object-contain opacity-40"
        />
        <div className={`effectContainer ${typeof hovered === "number" ? "hovered" + hovered : ""}`}>
          <StaticImage
            src="../images/background.jpeg"
            alt="Logo"
            className={`h-full w-screen object-contain absolute top-0 left-0 ${
              typeof hovered === "number" ? "hovered-image" + hovered : ""
            }`}
          />
        </div>
      </div>
      <div className="mt-22 flex">{props.children}</div>
    </div>
  );
};

export default BasicScreen;
